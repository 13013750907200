<template>
  <div class="ma-0 pa-5">
    <div class="d-flex justify-space-between align-center">
      <h2 class="pa-4 mx-0">
        Viewing Company: {{ this.CurrentSingleCompany.name }}
      </h2>
      <EditCompanyForm v-if="conditional_edit_company()" />
    </div>

    <!-- <v-btn @click="EditCompanyModal()">Edit</v-btn> -->
    <v-row class="ma-0">
      <v-col md="4" cols="12">
        <div
          class="table-cont"
          v-if="this.CurrentSingleCompany && this.CurrentSingleCompany.id"
        >
          <div class="table-header pa-3">
            <div class="table-property-label">Company Information</div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Name</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize"
            >
              {{ this.CurrentSingleCompany.name }}
            </div>
          </div>

          <div class="table-row long-text-row">
            <div class="table-property-label">Address</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.address }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Phone</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.phone }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Fax</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.fax }}
            </div>
          </div>

          <div class="table-row long-text-row">
            <div class="table-property-label">Email</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.email }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Customer Code</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.customer_code }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Sales Rep.</div>
            <div class="table-property-value">
              {{
                this.CurrentSingleCompany.sales_rep_id &&
                this.CurrentSingleCompany.sales_rep
                  ? this.CurrentSingleCompany.sales_rep.name
                  : ""
              }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Is customer?</div>
            <div class="table-property-value">
              <v-icon
                v-if="this.CurrentSingleCompany.is_customer"
                color="success"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Is supplier?</div>
            <div class="table-property-value">
              <v-icon
                v-if="this.CurrentSingleCompany.is_supplier"
                color="success"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Is subcontractor?</div>
            <div class="table-property-value">
              <v-icon
                v-if="this.CurrentSingleCompany.is_subcontractor"
                color="success"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Company Status</div>
            <div
              class="table-property-value"
              v-if="this.CurrentSingleCompany.company_status"
            >
              {{ this.CurrentSingleCompany.company_status.name }}
            </div>
            <div class="table-property-value" v-else></div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Terms</div>
            <div
              class="table-property-value"
              v-if="this.CurrentSingleCompany.term"
            >
              {{ this.CurrentSingleCompany.term.name }}
            </div>
            <div class="table-property-value" v-else>No term selected</div>
          </div>
        </div>
      </v-col>

      <v-col md="4" cols="12">
        <div
          class="table-cont"
          v-if="
            this.CurrentSingleCompany &&
            (this.CurrentSingleCompany.invoice_address ||
              this.CurrentSingleCompany.invoice_email)
          "
        >
          <div class="table-header pa-3">
            <div class="table-property-label">Invoice Details</div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Invoice Address</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize; white-space: break-spaces"
            >
              {{ this.CurrentSingleCompany.invoice_address }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Invoice Email</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize"
            >
              {{ this.CurrentSingleCompany.invoice_email }}
            </div>
          </div>
        </div></v-col
      >

      <v-col md="4" cols="12">
        <div
          class="map-iframe-cont d-flex flex-column align-stretch"
          style="height: 100%; min-height: 350px"
        >
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            v-if="this.CurrentSingleCompany.address"
            style="border: 0"
            :src="
              'https://www.google.com/maps/embed/v1/place?key=AIzaSyAb7dQgSBCZgzF-WGV4H0cUUFIUia3vZiU&q=' +
              this.CurrentSingleCompany.address
            "
            allowfullscreen
          >
          </iframe>
        </div>
      </v-col>
    </v-row>
    <CompanyContactsTable />
  </div>
</template>

<script>
import EditCompanyForm from "./EditCompanyForm.vue";
import CompanyContactsTable from "./CompanyContactsTable.vue";

export default {
  name: "SingleCompanyInfo",
  components: {
    EditCompanyForm,
    CompanyContactsTable,
  },
  data() {
    return {};
  },
  methods: {
    conditional_edit_company() {
      const routeName = this.$route.name;
      if (routeName == "SingleCustomerPage")
        return this.checkPermission("can_edit_customers");
      else if (routeName == "SingleSupplierPage")
        return this.checkPermission("can_edit_suppliers");

      return false;
    },
  },
  computed: {
    CurrentSingleCompany() {
      return this.$store.getters.GetCurrentSingleCompany;
    },
  },
  beforeDestroy() {
    // this.$store.commit("SetCurrentSingleCompany", null);
  },
};
</script>

<style>
</style>